export let baseUrl
export let staticContentBaseUrl
export let authorizationUrl
export let libertyCreditUrl

export let setBaseUrls = ({
  baseUrl: _baseUrl,
  staticContentBaseUrl: _staticContentBaseUrl,
  authorizationUrl: _authorizationUrl,
  libertyCreditUrl: _libertyCreditUrl
}) => {
  baseUrl = _baseUrl
  staticContentBaseUrl = _staticContentBaseUrl
  authorizationUrl = _authorizationUrl
  libertyCreditUrl = _libertyCreditUrl
}

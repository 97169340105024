import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl, libertyCreditUrl } from '../helpers/baseUrl'
import Download from 'downloadjs'

function fetchLoanHistoryAjax(loanId) {
  return jsonRequest(`${baseUrl}/loan/v1/loans/history?loanId=${loanId}`, true)
}
function fetchQisaLoanHistoryAjax(loanId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/qisa-history?loanId=${loanId}`,
    true
  )
}

export function exportLoanScheduleXls(
  loanId,
  isQisaLoan = false,
  success,
  error
) {
  if (!loanId) {
    return
  }

  const targetFilename = `LoanSchedule-${loanId}.xlsx`
  const mime = 'application/vnd.ms-excel'
  const url = isQisaLoan
    ? `${baseUrl}/loan/v1/loans/${loanId}/export/qisa-schedule/excell`
    : `${baseUrl}/loan/v1/loans/${loanId}/export/schedule/excell`

  jsonRequest(url, true)
    .then(res => {
      res
        .blob()
        .then(blb => {
          Download(blb, targetFilename, mime)
          success()
        })
        .catch(() => error())
    })
    .catch(() => error())
}

function fetchLoanScheduleAjax(loanId) {
  return jsonRequest(`${baseUrl}/loan/v1/loans/schedule?loanId=${loanId}`, true)
}

function fetchQisaLoanScheduleAjax(loanId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/qisa-schedule?loanId=${loanId}`,
    true
  )
}

export function exportLoanHistoryXls(
  loanId,
  isQisaLoan = false,
  success,
  error
) {
  if (!loanId) {
    return
  }
  const targetFilename = `LoanHistory-${loanId}.xlsx`
  const mime = 'application/vnd.ms-excel'
  const url = isQisaLoan
    ? `${baseUrl}/loan/v1/loans/${loanId}/export/qisa-history/excell`
    : `${baseUrl}/loan/v1/loans/${loanId}/export/history/excell`
  jsonRequest(url, true)
    .then(res => {
      res
        .blob()
        .then(blb => {
          Download(blb, targetFilename, mime)
          success()
        })
        .catch(() => error())
    })
    .catch(() => error())
}

function fetchLoanCalculatedResultAjax(loanId, date) {
  return jsonRequest(
    `${baseUrl}/loan/v1/calculation/calculated?loanId=${loanId}&calculationDate=${date}`,
    true
  )
}
function fetchQisaLoanCalculatedResultAjax(loanId, date) {
  return jsonRequest(
    `${baseUrl}/loan/v1/calculation/qisa-calculated?loanId=${loanId}&calculationDate=${date}`,
    true
  )
}

function fetchPrepaymentCalculatedResultAjax(loanId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/calculation/prepayment-calculated?loanId=${loanId}`,
    true
  )
}

function saveFriendlyName(loanId, friendlyName) {
  let params = {
    loanId: loanId,
    friendlyName: friendlyName
  }
  return jsonRequest(`${baseUrl}/loan/v1/loans`, true, params, 'PUT')
}

function fetchPrepaymentResultAjax(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/payments/${operationId}/prepayment`,
    true,
    undefined,
    'POST',
    {
      badRequest: false
    }
  )
}

export function prepaymentOperation(loanId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/payments/prepayment/operation?loanId=${loanId}`,
    true,
    undefined,
    'GET',
    {
      badRequest: false,
      internalServerError: false
    }
  )
}

export function fetchShowSkippingOfferAjax() {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/ShowSkippingOffer`,
    true,
    undefined,
    'GET',
    {
      badRequest: false,
      internalServerError: false
    }
  )
}

export function updateLoanSkippingResponseAjax(skipLoanAnswer) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/UpdateLoanSkippingResponse?skipLoanAnswer=${skipLoanAnswer}`,
    true,
    undefined,
    'PUT'
  )
}

export function requestMortgageOtp(phoneNumber) {
  return jsonRequest(
    `${baseUrl}/loan/v1/mortgage-request/send-sms`,
    true,
    { phoneNumber: phoneNumber },
    'POST',
    {
      badRequest: false
    }
  )
}

export function requestMortgageResendOtp(sessionId, phoneNumber) {
  return jsonRequest(
    `${baseUrl}/loan/v1/mortgage-request/resend-sms`,
    true,
    { sessionId, phoneNumber },
    'POST',
    {
      badRequest: false
    }
  )
}

export function fetchRSAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v2/loan-request/revenue-service/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function fetchCIAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v2/loan-request/credit-info/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function createMortgageRequest(params) {
  return jsonRequest(
    `${baseUrl}/loan/v1/mortgage-request`,
    true,
    { ...params },
    'POST'
  )
}

export function fetchClientLoans(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/liabilities/${operationId}`,
    true,
    undefined,
    'GET'
  )
}

export function fetchMortgageDistricts() {
  return jsonRequest(
    `${baseUrl}/configuration/v1/captions/districts`,
    true,
    undefined,
    'GET'
  )
}

export function fetchPreApprovedLoanDetails() {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/offers`,
    true,
    undefined,
    'GET'
  )
}
export function fetchLoanInsuranceProducts() {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/insurance-products`,
    true,
    undefined,
    'GET'
  )
}

export function fetchPreApprovedLoanPaymentDate(paymentDay) {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/first-payment-date?firstPaymentDay=${paymentDay}`,
    true,
    undefined,
    'GET'
  )
}

export function sendPreApprovedLoanCode(requestId) {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/send-sms`,
    true,
    requestId,
    'POST'
  )
}

export function scorePreApprovedLoan(params) {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/scoring`,
    true,
    params,
    'POST'
  )
}

export function registerPreApprovedLoan(params) {
  return jsonRequest(
    `${baseUrl}/loan/v3/offer-request/register-loan`,
    true,
    { ...params },
    'POST',
    { badRequest: false, internalServerError: false }
  )
}

export function disbursePreApprovedLoan(params) {
  return jsonRequest(
    `${baseUrl}/loan/v3/offer-request/disburse-loan`,
    true,
    params,
    'POST',
    { badRequest: false, internalServerError: false }
  )
}
export function requestLoanCode(params) {
  return jsonRequest(
    `${baseUrl}/loan/v3/offer-request/send-sms`,
    true,
    {
      requestId: params
    },
    'POST',
    { badRequest: false, internalServerError: false }
  )
}
export function fetchLoanAgreement(requestId) {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/agreement?requestId=${requestId}`,
    true
  ).then(res => res.blob())
}

export function fetchLoanSchedule(requestId) {
  return jsonRequest(
    `${baseUrl}/loan/v2/offer-request/schedule?requestId=${requestId}`,
    true
  ).then(res => res.blob())
}
export function fetchTopCardLink() {
  return jsonRequest(`${libertyCreditUrl}/topcard/start-application-url`, true)
}
export function getPreApprovedLoanAgreementByLoanId(
  loanId,
  fileName = 'Loan Agreement'
) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/agreement?loanId=${loanId}`,
    true
  )
    .then(res => {
      return res
        .blob()
        .then(blb => {
          return Download(blb, fileName, 'application/pdf')
        })
        .catch(() => {})
    })
    .catch(() => {})
}

export function downloadLoanDetailsPdf(id, fileName = 'Important Terms') {
  return jsonRequest(`${baseUrl}/loan/v1/loans/details/${id}/pdf`, true).then(
    res =>
      res.blob().then(blb => {
        return Download(blb, fileName, 'application/pdf')
      })
  )
}
export function downloadQisaLoanDetailsPdf(id, fileName = 'Important Terms') {
  return jsonRequest(
    `${baseUrl}/loan/v1/loans/qisa-details/${id}/pdf`,
    true
  ).then(res =>
    res.blob().then(blb => {
      return Download(blb, fileName, 'application/pdf')
    })
  )
}

export default {
  fetchLoanHistoryAjax,
  fetchQisaLoanHistoryAjax,
  fetchLoanScheduleAjax,
  fetchQisaLoanScheduleAjax,
  fetchLoanCalculatedResultAjax,
  fetchQisaLoanCalculatedResultAjax,
  fetchPrepaymentCalculatedResultAjax,
  fetchPrepaymentResultAjax,
  saveFriendlyName
}
// Micro loan
export function fetchMicroLoanRSAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/revenue-service/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function fetchMicroLoanCIAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/credit-info/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function fetchMicroLoanCheck() {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/check`,
    true,
    {},
    'POST'
  )
}

export function fetchMicroLoanConfigurations() {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/configuration`,
    true
  )
}

export function fetchMicroLoanCities() {
  return jsonRequest(`${baseUrl}/loan/v1/loan-request-micro/cities`, true)
}

export function fetchMicroLoanCityBranches(cityId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/cities/${cityId}`,
    true
  )
}

export function fetchMicroLoanObligations(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/liabilities/${operationId}`,
    true
  )
}

export function fetchMicroLoanCheckForPreparation(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro/lbsecutiry/check/${operationId}`,
    true,
    {},
    'POST'
  )
}
export function fetchMicroLoanCreation(body = {}) {
  return jsonRequest(
    `${baseUrl}/loan/v1/loan-request-micro`,
    true,
    body,
    'POST'
  )
}

// Consumer Loan Methods
export function fetchConsumerLoanRSAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/revenue-service/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function fetchConsumerLoanCreditInfoAgreement() {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/credit-info/agreement/pdf`,
    true
  ).then(res => res.blob())
}

export function fetchConsumerLoanOfferAgreement(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/offer/agreement/pdf/`,
    true,
    { operationId },
    'POST'
  ).then(res => res.blob())
}

export function fetchConsumerLoanSchedule(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/schedule?OperationId=${operationId}`,
    true
  ).then(res => res.blob())
}

export function fetchConsumerLoanOfferFirstPaymentDate(paymentDay) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/first-payment-date?firstPaymentDay=${paymentDay}`,
    true
  )
}

export function fetchConsumerLoanOffers(body) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/loan-offers`,
    true,
    body,
    'POST'
  )
}

export function fetchConsumerLoanInsuranceProducts() {
  return jsonRequest(`${baseUrl}/loan/v4/loan-request/insurance-products`, true)
}

export function fetchRegisterConsumerLoan(registerData) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/register-loan`,
    true,
    registerData,
    'POST'
  )
}

export function fetchDisburseLoan(disburseData) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/disburse-loan`,
    true,
    disburseData,
    'POST'
  )
}

export function requestLbLoanOtp(operationId) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/lbsecutiry/check/${operationId}`,
    true,
    undefined,
    'POST',
    {
      badRequest: false
    }
  )
}

export function checkClientContracts() {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/check`,
    true,
    undefined,
    'POST',
    {
      badRequest: false
    }
  )
}

// for older no offering flow
// export function checkLoanOtp(operationId, smsCode) {
//   return jsonRequest(
//     `${baseUrl}/loan/v2/loan-request/revenue-service/otp/check`,
//     true,
//     {
//       operationId: operationId,
//       code: smsCode
//     },
//     'POST'
//   )
// }

export function createConsumerLoanRequest(params) {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request`,
    true,
    { ...params },
    'POST'
  )
}

export function fetchConsumerLoanConfig() {
  return jsonRequest(
    `${baseUrl}/loan/v4/loan-request/configuration`,
    true,
    undefined,
    'GET'
  )
}

/***/

import React, { useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import LBCheckbox from '../../../common/SimpleComponents/LBCheckbox'
import '../../../../assets/scss/component/marketingConsents.scss'
import {
  fetchAvailableMarketingConsents,
  updateAvailableMarketingConsents
} from '../../../api/configuration.api'
import userApi from '../../../api/user-setting.api'
import { LbToast } from '../../../common/SimpleComponents/LBToasts'
import i18n from '../../../../i18n'
import { LbLoader } from '../../../common/SimpleComponents/LBLoader'

export const MarketingConsentsContainer = ({ onClose }) => {
  const [state, setState] = useState({ isLoading: true, items: [] })

  const handleToggleMarketingItem = useCallback(
    item => {
      let items = [...state.items]
      items = items.map(i => {
        if (i.typeId === item.typeId) {
          return {
            ...i,
            blocked: item.blocked === 1 ? 0 : 1
          }
        }
        return i
      })
      setState(prevState => ({ ...prevState, items }))
    },
    [state.items]
  )

  const handleSaveChanges = useCallback(async () => {
    setState(prevState => ({ ...prevState, isLoading: true }))
    try {
      const typeAnswers = state.items.map(i => ({
        typeId: i.typeId,
        answerId: 'blocked' in i ? i.blocked : 1
      }))

      await updateAvailableMarketingConsents({
        typeAnswers
      })
      setState(prevState => ({ ...prevState, isLoading: false }))
      onClose && onClose()
      let setting = {
        keyword: 'wasAddsIntroduced',
        settingBody: true
        // settingBody: ''
      }
      await userApi.saveUserSettingsAjax(setting, true)
      LbToast('success', i18n.t('MarketingConsents.SavedSuccessfully'))
    } catch (error) {
      setState(prevState => ({ ...prevState, isLoading: false }))
      LbToast('error', error.errors[0] || i18n.t('Common.Error'))
    }
  }, [state.items, onClose])

  const loadData = useCallback(async () => {
    try {
      const items = await fetchAvailableMarketingConsents()
      setState(prevState => ({ ...prevState, items, isLoading: false }))
    } catch (error) {
      LbToast('error', (error && error.errors[0]) || i18n.t('Common.Error'))
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])
  return (
    <>
      <LbLoader isLoading={state.isLoading} inCenter />
      <div className="lb-block-body fnt-16 fnt-low-m p-0 ">
        <div className="lb-confirm-text pb-0 pt-16">
          <p
            className="mb-20"
            dangerouslySetInnerHTML={{
              __html: i18n.t('MarketingConsents.Condition1')
            }}
          ></p>

          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t('MarketingConsents.Condition2')
            }}
          ></p>
        </div>

        <div className="pt-24 fnt-14 ph-24">
          {state.items.map(item => (
            <div
              className="flex-justify mb-15 bb pb-16"
              key={`MarketingConsents-${item.typeId}`}
            >
              <span>{item.typeName}</span>
              <LBCheckbox
                wrapClassName="fnt-12 dark-red"
                id={`togglePensionAgreement-${item.typeId}`}
                name="pensionCheck"
                checked={item.blocked == 0}
                onClick={() => handleToggleMarketingItem(item)}
                // disabled={this.state.checked}
              />
            </div>
          ))}
        </div>

        <div className="lb-confirm-text pb-0 pt-16">
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t('MarketingConsents.Condition3')
            }}
          ></p>
        </div>

        <div className="lb-popup-footer">
          <button
            className="lb-btn btn-red"
            id="close-MarketingConsentsContainer-modal"
            disabled={state.isLoading}
            // onClick={() => this.resetPinStatusModal.handleCloseModal()}
            onClick={handleSaveChanges}
          >
            <Trans i18nKey="Common.Confirm" />
          </button>
        </div>
      </div>
    </>
  )
}
